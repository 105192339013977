// Import our custom CSS
import '../scss/theme.scss'

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle'

// import styles bundle
import 'swiper/css/bundle'

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

function navbarBehaviour() {

    var navbar = document.querySelector("header .navbar.js--hideScroll");
    // Trovo l'altezza della navbar

    if (navbar) {
        var navbarHeight = navbar.offsetHeight;

        // Aggiungo l'altezza della navbar come padding all'elemento main
        var mainContent = document.querySelector('main.main-content');
        mainContent.style.paddingTop = navbarHeight + 'px';

        // Salvo la posizione dello scroll verticale
        var prevScrollpos = window.pageYOffset;
        var scrolledDown = false;

        // Evento scroll sull'oggetto window
        window.onscroll = function () {
            var currentScrollPos = window.pageYOffset;

            // Se l'user scrolla +200px si attiva l'effetto
            if (currentScrollPos > 200) {
                scrolledDown = true;
            } else {
                scrolledDown = false;
            }

            if (scrolledDown) {
                if (prevScrollpos > currentScrollPos) {
                    navbar.style.top = '0';
                } else {
                    navbar.style.top = '-' + navbarHeight + 'px';
                }
            }

            prevScrollpos = currentScrollPos;
        };
    }

}

navbarBehaviour();


jQuery(function ($) {

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        //>=, not <=
        if (scroll >= 150) {
            //clearHeader, not clearheader - caps H
            $("body").addClass("is-scrolled");
        } else {
            $("body").removeClass("is-scrolled");
        }
    }); //missing );


}); // jQuery End





const swiperHorizontal = new Swiper('.swiper-horizontal', {
    slidesPerView: 2,
    spaceBetween: 60,
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    // mousewheel: {
    //   enabled: true,
    //   sensitivity: 4,
    // },
    mousewheel: {
        forceToAxis: false,
        sensitivity: 4,
        releaseOnEdges: true,
    }
  });


  const swiper_quote = new Swiper('.swiper--quote', {
    loop: true,
    freeMode: true,
    slidesPerView: "auto",
    spaceBetween: 60,
    loop: true,
    autoplay: {
        delay: 0,
    },
    speed: 5000,
  
});



document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function(e) {
        e.preventDefault();

        const targetId = this.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    });
});
